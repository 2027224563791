import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { map } from 'rxjs/operators'
import { UserService } from '../services/user/user.service'

export const accetpedTermsGuardFn = () => {
  const userService = inject(UserService)
  const router = inject(Router)

  return userService.user$.pipe(
    map((user) => {
      return !!user.terms_accepted_date || router.createUrlTree(['/terms'])
    }),
  )
}
