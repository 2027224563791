import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { map } from 'rxjs'
import { UserService } from '../misc/services/user/user.service'

export const canActivateAcceptConditionsFn = () => {
  const userService = inject(UserService)
  const router = inject(Router)

  return userService.user$.pipe(map((user) => !user.terms_accepted_date || router.createUrlTree(['/dashboard'])))
}
