<div id='buttons' class='flex flex-col gap-4'>
  <h2>Buttons</h2>
  <div class='flex flex-col gap-4 w-fit'>
    <div class='flex flex-row gap-4'>
      <div class='flex flex-row gap-4 border-2 w-fit'>
        <label caption-2 bold>Shape</label>
        <input type='radio' name='shape' id='rounded' value='rounded' (change)='shape = $event.target.value'
          checked /><label for='rounded'>rounded</label>
        <input type='radio' name='shape' id='rectangle' value='rectangle'
          (change)='shape = $event.target.value' /><label for='rectangle'>rectangle</label>
      </div>
      <div class='flex flex-row gap-4 border-2 w-fit'>
        <span caption-2 bold>Icon</span>
        <select (change)="icon = $event.target.value">
          <option value='arrow-left'>arrow-left</option>
          <option value='arrow-right'>arrow-right</option>
        </select>
      </div>
      <div class='flex flex-row gap-4 border-2 w-fit'>
        <label caption-2 bold for='inverted'>Inverted</label>
        <input id='inverted' type='checkbox' (change)="inverted = $event.target.checked" />
      </div>
    </div>
    <div class='flex flex-row gap-4'>
      <div class='flex flex-row gap-4 border-2 w-fit'>
        <label caption-2 bold>color</label>
        <input type='radio' name='color' id='none' value='' (change)='color = $event.target.value' checked /><label
          for='none'>None</label>
        <input type='radio' name='color' id='danger' value='danger' (change)='color = $event.target.value' /><label
          for='danger'>Danger</label>
        <input type='radio' name='color' id='warning' value='warning' (change)='color = $event.target.value' /><label
          for='warning'>Warning</label>
        <input type='radio' name='color' id='success' value='success' (change)='color = $event.target.value' /><label
          for='success'>Success</label>
      </div>
      <div class='flex flex-row gap-4 border-2 w-fit'>
        <label caption-2 bold>Background</label>
        <input type='text' name='background' [value]='background' (change)='background = $event.target.value' />
      </div>
    </div>
  </div>
</div>
<div class='grid grid-flow-row gap-y-4 w-fit border-2' [style]="'background-color:' + background">
  <div class='grid grid-cols-6 items-center justify-items-center'>
    <span caption-3 bold>&nbsp;</span>
    <span caption-3 bold>Default</span>
    <span caption-3 bold>Icon left</span>
    <span caption-3 bold>Icon Right</span>
    <span caption-3 bold>Icon only</span>
    <span caption-3 bold>Disabled</span>
  </div>

  <div class='grid grid-cols-6 grid-rows-3 items-center justify-items-center py-4'>
    <h3 class='justify-self-start row-span-3'>Primary</h3>
    <button primary large [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button primary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button primary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button primary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button primary large [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button primary medium [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button primary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button primary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button primary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button primary medium [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button primary small [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button primary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button primary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button primary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button primary small [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>
  </div>

  <div class='grid grid-cols-6 grid-rows-3 items-center justify-items-center py-4'>
    <h3 class='justify-self-start row-span-3'>Secondary</h3>
    <button secondary large [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button secondary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button secondary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button secondary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button secondary large [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button secondary medium [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button secondary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button secondary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button secondary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button secondary medium [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button secondary small [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button secondary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button secondary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button secondary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button secondary small [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>
  </div>

  <div class='grid grid-cols-6 grid-rows-3 items-center justify-items-center py-4'>
    <h3 class='justify-self-start row-span-3'>Tertiary</h3>
    <button tertiary large [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button tertiary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button tertiary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button tertiary large [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button tertiary large [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button tertiary medium [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button tertiary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button tertiary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button tertiary medium [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button tertiary medium [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>

    <button tertiary small [color]='color' [inverted]='inverted' [shape]='shape'>Default</button>
    <button tertiary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"left"'>Icon
      left</button>
    <button tertiary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"right"'>Icon
      right</button>
    <button tertiary small [color]='color' [inverted]='inverted' [shape]='shape' [icon]='icon'
      [iconPosition]='"icon-only"'></button>
    <button tertiary small [color]='color' [inverted]='inverted' [shape]='shape' disabled>Disabled</button>
  </div>
</div>
