import { registerLocaleData } from '@angular/common'
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http'
import localeFr from '@angular/common/locales/fr'
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core'
import { MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core'
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field'
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { RouteReuseStrategy, Router } from '@angular/router'
import * as Sentry from '@sentry/angular-ivy'
import { CookieService } from 'ngx-cookie-service'
import { MarkdownModule } from 'ngx-markdown'
import { CookiesManagementService } from 'src/app/shared/services/cookies-management.service'
import { initDayjs } from './app-initializer/dayjs.initializer'
import { initUser } from './app-initializer/init-user.initializer'
import { initUserLanguageRedirection } from './app-initializer/user-language-redirection.initializer'
import { AppRoutingModule } from './app-routing.module'
import { AppComponent } from './app.component'
import { ArticleLibraryModule } from './article-library/article-library.module'
import { SnackFormErrorComponent } from './misc/components/snack-form-error/snack-form-error.component'
import { SnackbarErrorMattermostComponent } from './misc/components/snackbar-error-mattermost/snackbar-error-mattermost.component'
import { SnackbarErrorStudyComponent } from './misc/components/snackbar-error-study/snackbar-error-study.component'
import { SnackbarErrorComponent } from './misc/components/snackbar-error/snackbar-error.component'
import { SnackbarHTTPErrorComponent } from './misc/components/snackbar-http-error/snackbar-http-error.component'
import { SnackbarSuccessComponent } from './misc/components/snackbar-success/snackbar-success.component'
import { UnsubscribeNotificationComponent } from './misc/components/unsubscribe-notification/unsubscribe-notification.component'
import { ErrorInterceptor } from './misc/interceptors/error.interceptor'
import { SuccessInterceptor } from './misc/interceptors/success.interceptor'
import { TokenInterceptor } from './misc/interceptors/token.interceptor'
import { VersionInterceptor } from './misc/interceptors/version.interceptor'
import { AppRouteReuseStrategy } from './misc/other/app-route-reuse-strategy'
import { UserService } from './misc/services/user/user.service'
import { CookiesBannerComponent } from './shared/components/cookies-banner/cookies-banner.component'
import { HomeComponent } from './shared/components/home/home.component'
import { IconRegistryModule } from './shared/icon-registry/icon-registry.module'
import { AnonService } from './shared/services/anon.service'
import { SharedModule } from './shared/shared.module'

registerLocaleData(localeFr, 'fr-CA')

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 0,
    exitDuration: 0,
  },
}

const excludedRoutes = ['auth', 'resources', 'coach']

@NgModule({
  declarations: [
    AppComponent,
    SnackbarErrorComponent,
    SnackbarSuccessComponent,
    UnsubscribeNotificationComponent,
    SnackbarErrorMattermostComponent,
    SnackFormErrorComponent,
    SnackbarHTTPErrorComponent,
    SnackbarErrorStudyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    MatSnackBarModule,
    SharedModule,
    MarkdownModule.forRoot(),
    IconRegistryModule,
    ArticleLibraryModule,
    HomeComponent,
    CookiesBannerComponent,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true,
      }),
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [UserService, AnonService],
      useFactory: initUser,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [UserService, CookiesManagementService],
      useFactory: initUserLanguageRedirection,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [AnonService, UserService, Router, CookiesManagementService],
      useFactory: (anonServ: AnonService) => () => {
        if (!excludedRoutes.some((path) => location.pathname.includes(path))) {
          anonServ.setNfCookie()
        }
      },
    },
    // Init Sentry trace service
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initDayjs,
      multi: true,
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SuccessInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: VersionInterceptor, multi: true },
    { provide: MatSnackBarRef, useValue: {} },
    { provide: MAT_SNACK_BAR_DATA, useValue: {} },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { color: 'accent' } },
    [CookieService],
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
