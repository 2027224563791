import { animate, state, style, transition, trigger } from '@angular/animations'
import { CommonModule } from '@angular/common'
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core'
import { NavigationEnd, Router, RouterLink } from '@angular/router'
import { CookieService } from 'ngx-cookie-service'
import { filter, Subject, takeUntil } from 'rxjs'
import { LucilabCommonModule } from '../../lucilab-common/lucilab-common.module'
import { PendoButtonCtaDirective } from '../../Pendo/directives/pendo-button-cta.directive'
import { LocalStorageService } from '../../services/local-storage.service'

const animation = trigger('hideShow', [
  state(
    'show',
    style({
      bottom: '0',
      opacity: 1,
    }),
  ),
  state(
    'hide',
    style({
      bottom: '-400px',
      opacity: 0,
    }),
  ),
  transition('* => *', [animate('1s')]),
])

const STORAGE_KEY = 'cookies-accepted'

@Component({
  selector: 'app-cookies-banner',
  standalone: true,
  imports: [CommonModule, LucilabCommonModule, PendoButtonCtaDirective, RouterLink],
  providers: [CookieService],
  templateUrl: './cookies-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [animation],
})
export class CookiesBannerComponent implements OnDestroy {
  public isAccepted = true

  private readonly unsubscribe$ = new Subject<void>()

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
  ) {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$),
      )
      .subscribe(() => {
        const localStorageValue = this.localStorageService.getItem<string>(STORAGE_KEY) === 'accepted'
        if (this.isAccepted !== localStorageValue) {
          this.isAccepted = localStorageValue
          this.changeDetectorRef.markForCheck()
        }
      })
  }

  public ngOnDestroy(): void {
    this.unsubscribe$.next()
    this.unsubscribe$.complete()
  }

  public acceptCookies(): void {
    this.localStorageService.setItem(STORAGE_KEY, 'accepted')
    this.isAccepted = true
  }
}
