import { NgModule, Type } from '@angular/core'
import { ButtonComponent } from './button.component'
import * as Directives from './button.directive'

const components: Type<unknown>[] = [
  ButtonComponent,
  Directives.PrimaryDirective,
  Directives.SecondaryDirective,
  Directives.TertiaryDirective,
  Directives.DangerDirective,
  Directives.WarningDirective,
  Directives.SuccessDirective,
  Directives.ColorDirective,
  Directives.RoundedDirective,
  Directives.RectangleDirective,
  Directives.ShapeDirective,
  Directives.IconDirective,
  Directives.InvertedDirective,
  Directives.LargeDirective,
  Directives.MediumDirective,
  Directives.SmallDirective,
  Directives.SizeDirective,
]

@NgModule({
  imports: components,
  exports: components,
})
export class ButtonModule {}
