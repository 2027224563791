<ng-container *ngIf="customErrorMessage; else defaultError">
  <span>{{ customErrorMessage }}</span>
</ng-container>

<ng-template #defaultError>
  <ng-container *ngIf="httpErrorCode < 500; else serverFailureErrorMessage">
    <ng-container [ngSwitch]="httpErrorCode">
      <ng-container *ngSwitchCase="413">
        <span i18n='@@snackbar.http.error.413'>
          Le téléversement a échoué car le fichier dépasse la taille maximale autorisée (5 Mo). Veuillez choisir une image
          plus petite.
        </span>
      </ng-container>
  
      <ng-container *ngSwitchDefault>
        <ng-container [ngTemplateOutlet]="defaultErrorMessage" />
      </ng-container>
  
    </ng-container>
  </ng-container>
  
</ng-template>
<ng-template #defaultErrorMessage>
  <span i18n='@@snackbar.http.error.default'>
    Une erreur est survenue. <a class='underline' href='/a-propos/contact' target='_blank'>Contactez-nous</a> pour
    signaler le problème.
  </span>
</ng-template>

<ng-template #serverFailureErrorMessage>
  <span i18n='@@snackbar.http.error.500'>
    Une erreur est survenue. Veuillez réessayer plus tard.
  </span>
</ng-template>
