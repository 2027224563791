import { HttpStatusCode } from '@angular/common/http'
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core'
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar'

@Component({
  templateUrl: './snackbar-http-error.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SnackbarHTTPErrorComponent {
  public readonly httpErrorCode = this.data.httpErrorCode
  public readonly customErrorMessage = this.data.message

  constructor(@Inject(MAT_SNACK_BAR_DATA) private readonly data: {
    httpErrorCode: HttpStatusCode
    message?: string
  }) {}
}
