import { inject } from '@angular/core'
import { Router } from '@angular/router'
import { map, switchMap } from 'rxjs'
import { UserService } from '../../misc/services/user/user.service'

export const participantExtraDataGuardFn = () => {
  const userService = inject(UserService)
  const router = inject(Router)

  return userService.user$.pipe(
    switchMap((user) => userService.getExtraData(user.id)),
    map((extraData) => {
      const requiredKeys = ['age_group', 'gender', 'acquisition']
      const hasAllRequiredKeys = requiredKeys.every((key) => extraData.results.some((data) => data.key === key))

      return hasAllRequiredKeys || router.createUrlTree(['/inscription/completer-profil'])
    }),
  )
}
