<ng-container [ngSwitch]="theme">
  <ng-container *ngSwitchCase="'emptyNavBar'" [ngTemplateOutlet]="emptyNavBar" />
  <ng-container *ngSwitchCase="'unregistered'" [ngTemplateOutlet]="unregisteredNavBar" />
  <ng-container *ngSwitchCase="'registered'" [ngTemplateOutlet]="registeredNavBar" />

  <ng-template #emptyNavBar>
    <mat-sidenav-container>

      <mat-sidenav-content>

        <ng-container *ngTemplateOutlet='navbar; context: { $implicit: true }' />

        <div class="flex flex-col min-h-screen">

          <div class="flex-1 overflow-x-hidden w-full max-w-full bg-grey-1001 mt-[64px] lg:mt-[60px]">
            <ng-content />
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-template>

  <ng-template #unregisteredNavBar>
    <mat-sidenav-container>

      <mat-sidenav #sidenav mode="over" position="end">
        <div class="flex flex-col w-64 py-5">
          <ng-container *ngTemplateOutlet="nav" />
        </div>
      </mat-sidenav>

      <mat-sidenav-content>

        <ng-container *ngTemplateOutlet="navbar" />

        <div class="flex flex-col min-h-screen">
          <div class="bg-grey-200 overflow-x-hidden flex-1 mt-[64px] desktop:mt-[73px]">
            <ng-content select="[unregistered]" />
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-template>

  <ng-template #registeredNavBar>
    <mat-sidenav-container>

      <mat-sidenav #sidenav mode="over" position="end">
        <div class="flex flex-col w-64 py-5 mx-5">
          <ng-container *ngTemplateOutlet="nav" />
        </div>
      </mat-sidenav>

      <mat-sidenav-content>

        <ng-container *ngTemplateOutlet="navbar" />

        <div class="flex flex-col min-h-screen">
          <div class="flex-1 overflow-x-hidden w-full max-w-full bg-grey-1001 mt-[64px] desktop:mt-[73px]">
            <ng-content select="[registered]" />
          </div>
        </div>
      </mat-sidenav-content>
    </mat-sidenav-container>
  </ng-template>
</ng-container>

<ng-template #navbar let-empty>
  <div class="bg-white fixed top-0 left-0 right-0 z-50">
    <nav class="flex justify-between items-center max-w-[1200px] mx-auto h-[73px]">
      <a [routerLink]='[isAnon ? "/public/dashboard/home/detail" : "/dashboard/home/detail" ]' class="flex">
        <img src="assets/img/logo/luci.svg" width="82" alt="Luci logo">
      </a>
      <div *ngIf='!empty' class="hidden lg:flex grow justify-evenly mx-4">
        <ng-container *ngTemplateOutlet="nav; context" />
      </div>
      <div class="flex items-center">
        <ng-container *ngIf='isAnon'>
          <a routerLink='/auth/login' class='hidden lg:block lu-btn lu-btn-green mr-2.5 hover:text-white' i18n>Me
            connecter</a>
          <button [matMenuTriggerFor]="langMenu" [ngClass]='{"hidden lg:flex": isAnon}'
            class='font-bold uppercase flex items-center mx-5' (click)='langToggle = !langToggle'>{{ localeId
            }}<mat-icon [fontIcon]='langToggle ? "expand_less" : "expand_more"' /></button>
        </ng-container>
        <a *ngIf='!isAnon' routerLink='/faq' class="flex p-3 text-blue-500 pendo_navbar_faq_button"><mat-icon
            fontIcon="help" /></a>
        <button (click)="sidenav.open()" *ngIf='!empty' class="flex p-3 text-4xl lg:hidden">
          <mat-icon inline fontIcon="menu" />
        </button>
        <button *ngIf='!isAnon && user$ | async as user' [matMenuTriggerFor]="menu" [class.hidden]='!empty'
          class="lg:block mr-3 w-9 h-9 text-white bg-green-500 rounded-full bg-center bg-cover"
          [style.backgroundImage]=' user.avatar_data ? "url(" + user.avatar_data?.medias.thumbnail_retina + ")" : ""'>
          <span *ngIf='!isAnon && !user.avatar' class='font-bold text-sm'>{{ userInitals }}</span>
        </button>
      </div>
    </nav>
  </div>
</ng-template>

<ng-template #nav>
  <ng-container *ngIf='(user$ | async) as user'>
    <ng-container *ngIf='user.anonymous ?? true; else registredMenu'>
      <a routerLink='/public/dashboard/home/detail'
        routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center px-5 py-2.5 text-grey-1000'>
        <mat-icon class='text-grey-400 mr-2.5' fontIcon="home" />
        <span i18n>Accueil</span>
      </a>
      <a routerLink='/public/dashboard/home/discover'
        routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center px-5 py-2.5 text-grey-1000'>
        <mat-icon fontIcon='toggle_on' class='text-grey-400 mr-2.5' /><span i18n>Découvrir Luci</span>
      </a>

      <a routerLink='/podcast' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center px-5 py-2.5 text-grey-1000'>
        <mat-icon fontIcon="mic" class='text-grey-400 mr-2.5' />
        <span i18n>Balado</span>
      </a>
      <a routerLink='/categories' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        [ngClass]="{'!text-blue-500 font-bold [&>.mat-icon]:text-blue-500': isLibraryActive}"
        class='flex items-center px-5 py-2.5 text-grey-1000'>
        <mat-icon svgIcon="lib" class='text-grey-400 mr-2.5 p-0.5' />
        <span i18n>Bibliothèque</span>
      </a>
    </ng-container>

    <ng-template #registredMenu>
      <a routerLink='/dashboard/home/detail' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon class='text-grey-400 mr-2.5' fontIcon="home" />
        <span i18n>Accueil</span>
      </a>

      <a routerLink='/dashboard/home/profile' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon fontIcon="account_circle" class='text-grey-400 mr-2.5' />
        <span i18n>Mon bilan</span>
      </a>

      <a routerLink='/objectifs' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon fontIcon="fact_check" class='text-grey-400 mr-2.5' />
        <span i18n>Mes objectifs</span>
      </a>

      <a *ngIf='!user.coach' routerLink='/dashboard/home/my-action-plan'
        routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon fontIcon="emoji_people" class='text-grey-400 mr-2.5' />
        <span i18n>Mon conseiller</span>
      </a>

      <a routerLink='/podcast' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon fontIcon="mic" class='text-grey-400 mr-2.5' />
        <span i18n>Balado</span>
      </a>

      <a routerLink='/categories' routerLinkActive='!text-blue-500 font-bold [&>.mat-icon]:text-blue-500'
        [ngClass]="{'!text-blue-500 font-bold [&>.mat-icon]:text-blue-500': isLibraryActive}"
        class='flex items-center py-2.5 text-grey-1000'>
        <mat-icon svgIcon="lib" class='text-grey-400 mr-2.5 p-0.5' />
        <span i18n>Bibliothèque</span>
      </a>
    </ng-template>

    <div class="lg:hidden">
      <ng-container *ngTemplateOutlet="submenu" />
    </div>

  </ng-container>
</ng-template>

<ng-template #submenu>
  <div class="bg-white flex flex-col">
    <hr class="my-4 border-t-2 mx-2.5 lg:hidden">
    <a *ngIf='!isAnon' routerLink="/dashboard/home/settings" class='px-6 py-3 lg:hover:bg-grey-1001' i18n>
      Paramètres de compte
    </a>
    <button (click)='langToggle = !langToggle; $event.stopPropagation()'
      class='font-bold uppercase px-6 py-3 flex justify-between items-center'>{{ localeId }}<mat-icon
        [fontIcon]='langToggle ? "expand_less" : "expand_more"' /></button>
    <div [ngClass]='{"hidden": !langToggle}' class='flex flex-col'>
      <button class='text-left p-3 text-sm mx-3' *ngFor='let l of languages$ | async | keyvalue'
        (click)="updateLanguage(l)" [ngClass]="{'bg-grey-1001': localeId === l.key}" pendoClass="change-language"> {{
        l.value }}</button>
    </div>
    <app-invite-friend-cta />
    <hr class="mt-4 border-t-2 mx-2.5 mb-5">
    <div *ngIf='!isAnon' class='flex flex-col mx-3'>
      <button (click)='logout()' class='lu-btn lu-btn-orange mb-2.5 !text-white' i18n>
        Me déconnecter
      </button>
    </div>
    <div *ngIf='isAnon' class='flex flex-col mx-3 [&>a]:mb-2.5 [&>a]:text-white'>
      <a routerLink='/auth/login' class='lu-btn lu-btn-green' i18n>Me connecter</a>
      <a routerLink='/auth/inscription' class='lu-btn lu-btn-orange' i18n>
        Créer mon compte
      </a>
    </div>
  </div>
</ng-template>

<mat-menu #menu='matMenu' xPosition="before">
  <ng-container *ngTemplateOutlet="submenu" />
</mat-menu>

<mat-menu #langMenu='matMenu'>
  <div class='flex flex-col'>
    <button class='text-left p-3 text-sm w-40' *ngFor='let l of languages$ | async | keyvalue'
      (click)="updateLanguage(l)" [ngClass]="{'bg-grey-1001': localeId === l.key}" pendoClass="change-language"> {{
      l.value }}</button>
  </div>
</mat-menu>
