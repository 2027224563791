import { inject } from '@angular/core'
import { CanMatchFn, Router } from '@angular/router'
import { combineLatest } from 'rxjs'
import { map } from 'rxjs/operators'
import { ConfigService } from '../config/config.service'
import { UserService } from '../services/user/user.service'

export const ParticipationReasonFormCompletedGuardFn: CanMatchFn = () => {
  const userService = inject(UserService)
  const configServ = inject(ConfigService)
  const router = inject(Router)

  return combineLatest({ user: userService.user$, config: configServ.config$ }).pipe(
    map(({ user, config }) => {
      return (
        user.participation_reason_given ||
        router.createUrlTree(['/dashboard/form'], {
          queryParams: {
            form_id: config.user_participation_form_id,
          },
        })
      )
    }),
  )
}
